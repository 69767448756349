import {
  GET_APPLICATION,
  CREATE_APPLICATION,
  UPDATE_APPLICATION,
  DELETE_APPLICATION,
  GET_SINGLE_APPLICATION,
} from "../actions/types";

const initialState = {
  application: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATION:
      return {
        ...state,
        applications: action.payload
      };
    case GET_SINGLE_APPLICATION:
        return {
          ...state,
          application: action.payload || null
        };
    case CREATE_APPLICATION:
      return {
        ...state,
        application: action.payload !== undefined ? action.payload.data : null,
      };
    case UPDATE_APPLICATION:
      const objIndex = state.applications.data.findIndex((obj => obj.id === action.payload.data.id))
      state.applications.data[objIndex].application_name = action.payload.data.application_name
      state.applications.data[objIndex].description = action.payload.data.description
      return {
        ...state,
        applications: { data: state.applications.data.filter((el) => { return el !== null }) } || null
        };
    case DELETE_APPLICATION:
        const info = [...state.applications.data.filter((app) => app.id !== action.payload)]
        const info1 = { 
          data: info || null
        }
      return {
        ...state,
        applications: info1 || null,
      };
    default:
      return state;
  }
}
