import React from 'react'


export function LandingPage() {

//   useEffect(async () => {
//     dispatch(getCategories())
//         .then(res => {
//             setCategoryLength(res.data.length)
//             setLoaded(true)
//         })

//     dispatch(getApplications())
//         .then(res => {
//             setApplicationLength(res.data.length)
//             setLoaded(true)
//     })
// }, [])
    return (
        <>
            {/* <!-- WELCOME --> */}
            <section className="py-10 py-md-14 bg-primary" id="welcome">
        
            {/* <!-- Shape --> */}
            <div className="shape shape-blur-3 text-white">
                <svg viewBox="0 0 1738 487" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z" fill="url(#paint0_linear)"/><defs><linearGradient id="paint0_linear" x1="0" y1="0" x2="1049.98" y2="912.68" gradientUnits="userSpaceOnUse"><stop stopColor="currentColor" stopOpacity=".075"/><stop offset="1" stopColor="currentColor" stopOpacity="0"/></linearGradient></defs></svg>      </div>
        
            {/* <!-- Content --> */}
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 text-center">
        
                    {/* <!-- Heading --> */}
                    <h1 className="display-2 fw-bold text-white">
                    Teamwork Made Easy.
                    </h1>
        
                    {/* <!-- Text --> */}
                    <p className="lead text-white mb-8">
                    Forward-thinking businesses use Landkit to create collaborative workspaces for their projects.
                    </p>
        
                    {/* <!-- Button --> */}
                    <a href="#!" className="btn btn-white shadow lift">
                    Create your workspace
                    </a>
        
                </div>
                </div> {/* <!-- / .row --> */}
            </div> {/* <!-- / .container --> */}
        
            </section>
        
            {/* <!-- SHAPE --> */}
            <div className="position-relative">
            <div className="shape shape-bottom shape-fluid-x text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor"/></svg>      </div>
            </div>
        
            {/* <!-- ABOUT --> */}
            <section className="pt-8 pt-md-10" id="about">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
    
                {/* <!-- Button --> */}
                <a href="#about" className="btn btn-white btn-rounded-circle shadow mt-n12 mt-md-n14" data-scroll>
                  <i className="fe fe-arrow-down"></i>
                </a>
    
              </div>
            </div> {/* <!-- / .row --> */}
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">
    
                {/* <!-- Heading --> */}
                <h2 className="fw-bold">
                  The hub for all your <span className="text-primary">communication and work</span>.
                </h2>
    
                {/* <!-- Text --> */}
                <p className="fs-lg text-muted mb-9">
                  Landkit is where your team can come together to get stuff done. Chat, files, wikis, documentation, and more.
                </p>
    
              </div>
            </div> {/* <!-- / .row --> */}
            <div className="row">
              <div className="col-12 col-lg-6">
    
                {/* <!-- Item --> */}
                <div className="row align-items-center mb-8" data-aos="fade-up">
                  <div className="col-4 col-lg-5">
    
                    {/* <!-- Image --> */}
                    <img src="assets/img/illustrations/illustration-4.png" alt="..." className="img-fluid" />
    
                  </div>
                  <div className="col-8 col-lg-7">
    
                    {/* <!-- Heading --> */}
                    <h3 className="fw-bold mb-2">
                      Category
                    </h3>
    
                    {/* <!-- Text --> */}
                    <p className="text-gray-700 mb-0">
                      Communicate with your team in public or private chats with individuals or groups.
                    </p>
                    {/* <!-- Badge --> */}
                    <span className="badge rounded-pill bg-success-soft mb-3">
                      {/* <span className="h6 text-uppercase">{ loaded ? categoryLength : null } { categoryLength > 1 ? "categories" : "category"}</span> */}
                    </span>
    
                  </div>
                </div> {/* <!-- / .row --> */}
 
    
              </div>
              <div className="col-12 col-lg-6">
                {/* <!-- Item --> */}
                <div className="row align-items-center mb-8" data-aos="fade-up">
                  <div className="col-4 col-lg-5">
    
                    {/* <!-- Image --> */}
                    <img src="assets/img/illustrations/illustration-7.png" alt="..." className="img-fluid" />
    
                  </div>
                  <div className="col-8 col-lg-7">
    
                    {/* <!-- Heading --> */}
                    <h3 className="fw-bold mb-2">
                      Application
                    </h3>
    
                    {/* <!-- Text --> */}
                    <p className="text-gray-700 mb-0">
                      Keep track of what's happening in your company from a centralized dashboard.
                    </p>
                      {/* <!-- Badge --> */}
                      <span className="badge rounded-pill bg-success-soft mb-3">
                    {/* <span className="h6 text-uppercase">{ loaded ? applicationLength : null } { applicationLength > 1 ? "applications" : "application"}</span> */}
                      </span>
    
                  </div>
                </div> {/* <!-- / .row --> */}
    
 
              </div>
            </div> {/* <!-- / .row --> */}
          </div> {/* <!-- / .container --> */}
        </section>
    
        {/* <!-- INTEGRATIONS --> */}
        <section className="pt-8 pt-md-11">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 text-center">
    
                {/* <!-- Badge --> */}
                <span className="badge rounded-pill bg-success-soft mb-3">
                  <span className="h6 text-uppercase">Integrations</span>
                </span>
    
                {/* <!-- Heading --> */}
                <h2 className="fw-bold">
                  Integrated with your favorite platforms
                </h2>
    
                {/* <!-- Text --> */}
                <p className="fs-lg text-gray-700 mb-9">
                  Your data should be connected and portable. Landkit connects with other sources to help you get more done.
                </p>
    
              </div>
            </div> {/* <!-- / .row --> */}
  
          </div> {/* <!-- / .container --> */}
        </section>
        </>
    )
}