import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
// import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import * as authCrud from "../_redux/authCrud";
import Swal from "sweetalert2";

const initialValues = {
  public_key: "",
  private_key: "",
  module_id: "",
};

function Login(props) {
  const dispatch = useDispatch();

  let useparams = useParams();

  const [public_key, setPublicKey] = useState(
    useparams.public_key && decodeURIComponent(useparams.public_key)
  );
  const [private_key, setPrivateKey] = useState(
    useparams.private_key && decodeURIComponent(useparams.private_key)
  );
  const [module_id, setModuleId] = useState(
    useparams.module_id && decodeURIComponent(useparams.module_id)
  );

  const [loading, setLoading] = useState(false);

  // const [process_keys_in_url, processKeysInURL] = useState(
  //   public_key_temp && private_key_temp && module_id_temp ? true : false
  // );

  const LoginSchema = Yup.object().shape({
    public_key: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required Field"),
    private_key: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(500, "Maximum 500 symbols")
      .required("Required Field"),
    module_id: Yup.string()
      .min(1, "Minimum 1 symbols")
      .required("Required Field"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setPrivateKey(values.private_key);
      setPublicKey(values.public_key);
      setModuleId(values.module_id);
    },
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const login_with_user_module = async (tokentemp, module_id) => {
    setLoading("login_with_user_module");

    let response = {};
    response = await authCrud.login_with_user_module({
      token: tokentemp,
      module_id: module_id,
    });

    console.log(response);
    response = response.data;
    setLoading("");
    if (response.status) {
      //we have token in this response
      let authToken = response.token;
      let user = response.user;

      //save this token

      dispatch(auth.actions.login(authToken));
      dispatch(auth.actions.fulfillUser(user));
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: response.message,
        confirmButtonColor: "#1BC5BD",
      });
    }
  };

  const login_using_keys = async () => {
    //we need to send this data to backend

    let map = {
      public_key,
      private_key,
      module_id,
    };

    setLoading("login_using_keys");
    let response = await authCrud.login_using_keys(map);
    response = response.data;
    console.log(response)
    setLoading("");
    if (response && response.status) {
      let authToken = response.token;
      let user = response.user;

      dispatch(auth.actions.login(authToken));
      dispatch(auth.actions.fulfillUser(user));
    } else {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        html: response?.message || "Error!!!",
        confirmButtonColor: "#1BC5BD",
      });
    }
  };

  useEffect(() => {
    if (public_key && private_key && module_id) {
      login_using_keys();
    }
  }, [public_key, private_key, module_id]);

  useEffect(() => {
    window.addEventListener(
      "message",
      async (event) => {
        let url = process.env.REACT_APP_USER_MODULE_URL;
        url = url.split("/");

        var protocol = url[0];
        var host = url[2];
        var origin = protocol + "//" + host;
        if (event.origin === origin) {
          //window.location.href = "<%= redirectURL %>/moduleauthenticated?token=" + event.data.token + '&module_id=' + event.data.module_id

          //we need to send this token to API, and check that is this a new user or a previous user
          //if this is a new user then we need to ask them for their role i.e. Teacher or student
          setLoading("login_with_user_module");
          login_with_user_module(event.data.token, event.data.module_id);
        }
        return;
      },
      false
    );
    return;
  }, []);

  return (
    <>
    {/* <!-- CONTENT --> */}
    <section className="section-border border-primary">
        <div className="container d-flex flex-column">
          <div className="row align-items-center justify-content-center gx-0 min-vh-100">
            <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
  
              {/* <!-- Heading --> */}
              <h1 className="mb-0 fw-bold text-center">
                Sign in
              </h1>
  
              {/* <!-- Text --> */}
              <p className="mb-6 text-center text-muted">
                Simplify your workflow in minutes.
              </p>
  
              {/* <!-- Form --> */}
              <form className="mb-6" onSubmit={formik.handleSubmit}>
  
              {formik.status ? (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <div className="alert-text font-weight-bold">{formik.status}</div>
                </div>
              ) : (
                ""
              )}
                {/* <!-- Email --> */}
                <div className="form-group">
                  {/* <label className="form-label">
                    Public Key
                  </label> */}
                  <input
                    // onChange={(e) => setPublicKey(e.target.value)}
                    className={`form-control h-auto  rounded-pill py-4 px-8 ${getInputClasses(
                      "public_key"
                    )}`}
                    type="text"
                    id="public_key"
                    placeholder="Public key"
                    name="public_key"
                    autoComplete="off"
                    {...formik.getFieldProps("public_key")}
                  />
                </div>

                {/* <!-- Email --> */}
                <div className="form-group">
                  {/* <label className="form-label">
                    Private Key
                  </label> */}
                  <input
                    // onChange={(e) => setPrivateKey(e.target.value)}
                    className={`form-control h-auto  rounded-pill py-4 px-8 ${getInputClasses(
                      "private_key"
                    )}`}
                    type="text"
                    id="private_key"
                    placeholder="Private key"
                    name="private_key"
                    autoComplete="off"
                    {...formik.getFieldProps("private_key")}
                  />
                </div>
  
                {/* <!-- Password --> */}
                <div className="form-group mb-5">
                  {/* <label className="form-label">
                    Module Id
                  </label> */}
                  <input
                    // onChange={(e) => setModuleId(e.target.value)}
                    className={`form-control h-auto rounded-pill py-4 px-8 ${getInputClasses(
                      "module_id"
                    )}`}
                    type="text"
                    id="module_id"
                    placeholder="Module ID"
                    name="module_id"
                    {...formik.getFieldProps("module_id")}
                  />
                </div>
  
                {/* <!-- Submit --> */}
                <button id="kt_login_signin_submit1" className="btn w-100 btn-primary" type="submit" disabled={loading}>
                  Sign in
                  {loading === "login_using_keys" && (
                    <span className="spinner-border text-light"></span>
                  )}
                </button>
  
              </form>
              <p className="opacity-90 text-center">OR</p>
  
              <button className="btn w-100 btn-primary" 
                id="kt_login_signin_submit"
                onClick={() =>
                  window.open(
                    process.env.REACT_APP_USER_MODULE_URL,
                    "test",
                    "width=500,height=500"
                  )
                }
              >
              <span
                className={`${
                  loading === "login_with_user_module" ? "pr-3" : ""
                }`}
              >
                Sign In With User Module{" "}
              </span>
              {loading === "login_with_user_module" && (
                <span className="spinner-border text-light"></span>
              )}
                </button>
              {/* <!-- Text --> */}
              {/* <p className="mb-0 fs-sm text-center text-muted">
                Don't have an account yet <a href="signup.html">Sign up</a>.
              </p> */}
  
            </div>
          </div> {/* <!-- / .row --> */}
        </div> {/* <!-- / .container --> */}
      </section>
  
    </>
  );
}

export default connect(null, auth.actions)(Login);
// public_key - BGdinnqX4QkYlgBhDnqTKhk0btJ2ud
// private_key - U2FsdGVkX1+8UlOfpWxoVWJqZ6cCVaqLqcRK28yONghoFO9KrPRVSyRHNqQiCAd1

// {
//     "public_key": "BGdinnqX4QkYlgBhDnqTKhk0btJ2ud",
//     "private_key": "U2FsdGVkX1+8UlOfpWxoVWJqZ6cCVaqLqcRK28yONghoFO9KrPRVSyRHNqQiCAd1",
//     "module_id": "40"
// }
