import axios from "axios";
import {
  CREATE_APPLICATION,
  GET_APPLICATION,
  GET_SINGLE_APPLICATION,
  UPDATE_APPLICATION,
  DELETE_APPLICATION,
} from "./types";

const APPLICATION_URL = process.env.REACT_APP_API_ENDPOINT + "/applications";

export const getApplications = () => async (dispatch) => {
  const res = await axios.get(APPLICATION_URL);

  dispatch({ type: GET_APPLICATION, payload: res.data });
  return res.data;
};

export const deleteApplication = (values) => async (dispatch) => {
  const res = await axios.delete(`${APPLICATION_URL}/${values}`);

  // console.log(res);
  dispatch({ type: DELETE_APPLICATION, payload: values });
  return res.data;
};

export const createApplication = (values) => async (dispatch) => {
  const res = await axios.post(APPLICATION_URL, {
    application: {
      application_name: values.application,
      application_description: values.description,
    },
  });

  dispatch({ type: CREATE_APPLICATION, payload: res.data });
  return res.data;
};

export const updateApplication = (values, id) => async (dispatch) => {
  const application = {
    application_name: values.application,
    application_description: values.description,
  };
  const res = await axios.put(`${APPLICATION_URL}/${id}`, { application });

  dispatch({ type: UPDATE_APPLICATION, payload: res.data });
  return res.data;
};

export const getSingleApplication = (id) => async (dispatch) => {
  const res = await axios.get(`${APPLICATION_URL}/${id}`);

  dispatch({ type: GET_SINGLE_APPLICATION, payload: res.data });
  return res.data;
};
