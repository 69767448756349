import axios from "axios";
import {
  GET_DOCUMENT,
  GET_SINGLE_DOCUMENT,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
} from "./types";

const DOCUMENT_URL = process.env.REACT_APP_API_ENDPOINT + "/documents";

export const getDocuments = () => async (dispatch) => {
  const res = await axios.get(DOCUMENT_URL);

  dispatch({ type: GET_DOCUMENT, payload: res.data });
  return res.data;
};

export const getDocument = (id) => async (dispatch) => {
  const res = await axios.get(`${DOCUMENT_URL}/${id}`);

  dispatch({ type: GET_SINGLE_DOCUMENT, payload: res.data });
  return res.data;
};

export const createDocument = (values) => async (dispatch) => {
  const document = {
    subject: values.subject,
    description: values.description,
    categoryId: values.category,
    applicationId: values.application,
    type: values.type,
  };

  const res = await axios.post(DOCUMENT_URL, { document });

  dispatch({ type: CREATE_DOCUMENT, payload: res.data });
  return res.data;
};

export const editDocument = (values) => async (dispatch) => {
  const document = {
    subject: values.subject,
    description: values.description,
    categoryId: parseInt(values.category),
    applicationId: parseInt(values.application),
    type: values.type,
    status: values.status,
  };

  const res = await axios.put(`${DOCUMENT_URL}/${values.id}`, { document });

  dispatch({ type: UPDATE_DOCUMENT, payload: res.data });
  return res.data;
};

export const deleteDocument = (id) => async (dispatch) => {
  const res = await axios.delete(`${DOCUMENT_URL}/${id}`);

  dispatch({ type: DELETE_DOCUMENT, payload: id });
  return res.data;
};
