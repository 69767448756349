import Swal from "sweetalert2";
import React, { Component } from "react";
import { Link, generatePath } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDocuments,
  deleteDocument,
} from "../../../modules/Document/_redux/actions";
import { getApplications } from "../../../modules/Application/_redux/actions";
import * as ReactBootStrap from "react-bootstrap";

var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

class DocumentationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      noOfDocuments: 0,
      id: "",
      name: "",
      category: "",
      application: "",
      subject: "",
      description: "",
    };
  }

  async componentWillMount() {
    await this.props.getDocuments();
    this.props.getApplications();
    if (this.props.documents) {
      this.setState({
        loaded: true,
        noOfDocuments: this.props.documents.data
          ? this.props.documents.data.length
          : null,
      });
    }
  }

  handleProceed(id) {
    id &&
      this.props.history.push(
        generatePath("/application/:id/documents", { id })
      );
  }

  handleEditPageProceed(id) {
    id && this.props.history.push(generatePath("/edit-document/:id", { id }));
  }

  confirmDelete(id) {
    Swal.fire({
      text: "Are you sure you want to delete?",
      icon: "error",
      showCancelButton: true,
      buttonsStyling: true,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: "No, cancel",
      customClass: {
        confirmButton: "btn font-weight-bold btn-primary",
        cancelButton: "btn font-weight-bold btn-default",
      },
    }).then((result) => {
      if (result.value) {
        const { deleteDocument } = this.props;
        deleteDocument(id)
          .then((res) => {
            if (res.status === true) {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                html: "Document deleted successfully",
                confirmButtonColor: "#1BC5BD",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                html: res.message,
                confirmButtonColor: "#1BC5BD",
              });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  }

  renderDocuments() {
    var counter = 0;
    var d;
    if (this.state.loaded && this.props.documents.data !== undefined) {
      return this.props.documents.data.map((document) => {
        {
          d = new Date(document.updatedAt);
        }
        {
          counter += 1;
        }
        return (
          <>
            <div className="accordion-item">
              {/* <!-- Button --> */}
              <div
                className="accordion-button collapsed"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target={`#help${counter}`}
                aria-expanded="false"
                aria-controls={`help${counter}`}
              >
                {/* <!-- Title --> */}
                <span className="me-4" id={`help${counter}Heading`}>
                  {document.subject}
                </span>

                {/* <!-- Metadata --> */}
                <div className="text-muted ms-auto">
                  {/* <!-- Text --> */}
                  <span className="fs-sm me-4 d-none d-md-inline">
                    Last Updated: {weekday[d.getDay()]}
                  </span>
                </div>
              </div>

              {/* <!-- Collapse --> */}
              <div
                className="accordion-collapse collapse"
                id={`help${counter}`}
                aria-labelledby={`help${counter}Heading`}
                data-bs-parent={`#helpAccordion${counter}`}
              >
                <div className="accordion-body">
                  {/* <!-- Text --> */}
                  <p className="text-gray-700">{document.description}</p>
                  <div className="d-flex">
                    <div>
                      <i
                        className="fa fa-edit"
                        onClick={() => {
                          this.handleEditPageProceed(document.id);
                        }}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                    <div>
                      <i
                        className="fa fa-trash"
                        onClick={() => {
                          this.confirmDelete(document.id);
                        }}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      });
    } else {
      this.props.documents.data = [];
    }
  }

  renderApplication() {
    if (this.state.loaded && this.props.applications !== undefined) {
      let applications = this.props.applications.data.filter((el) => {
        return el !== null;
      });

      let firstThreeApp = applications.slice(0, 3);

      return firstThreeApp.map((application) => {
        return (
          <div className="col-12 col-md-6 col-lg-4" key={application.id}>
            {/* Card */}
            <div className="card card-border border-primary shadow-lg mb-6 mb-md-8">
              <div className="card-body text-center">
                {/* Icon */}
                <div className="icon-circle bg-primary text-white mb-5">
                  <i className="fe fe-users"></i>
                </div>

                <div className="d-flex justify-content-end">
                  <div
                    className="dropdown dropdown-inline"
                    data-toggle="tooltip"
                  ></div>
                </div>

                {/* Heading */}
                <h4 className="fw-bold">{application.application_name}</h4>

                {/* Text */}
                <p className="text-gray-700 mb-5">{application.description}</p>

                {/* Badge */}
                <span className="badge rounded-pill bg-dark-soft">
                  <span className="h6 text-uppercase">
                    {application.documents
                      ? [application.documents.length, " Documents"]
                      : [0, " Document"]}
                  </span>
                </span>

                {/* Badge */}
                <span
                  className="badge rounded-pill bg-success-soft mt-5 me-4"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    return this.handleProceed(application.id);
                  }}
                >
                  <span className="h6 text-uppercase">Browse Documents</span>
                </span>
              </div>
            </div>
          </div>
        );
      });
    }
  }
  render() {
    return (
      <>
        {/* <!-- BREADCRUMB --> */}
        <nav className="bg-gray-200">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* <!-- Breadcrumb --> */}
                <ol className="breadcrumb breadcrumb-scroll">
                  <li className="breadcrumb-item">
                    <Link to="/" className="text-gray-700">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Documentation
                  </li>
                  <input type="text" autoFocus className="invisibleInput" />
                </ol>
              </div>
            </div>
          </div>
        </nav>
        <section className="pt-6 pt-md-8 pb-8">
          <div className="container" style={{ maxWidth: "1000px" }}>
            <div className="row">
              <div className="col-12">
                <div className="row mb-6 mb-md-8">
                  <div className="col-auto">
                    {/* <!-- Icon --> */}
                    <div className="icon-circle bg-primary text-white">
                      <i className="fe fe-users"></i>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between col ms-n4">
                    <div>
                      {/* <!-- Heading --> */}
                      <h2 className="fw-bold mb-0">Documentation</h2>

                      {/* <!-- Text --> */}
                      <p className="fs-lg text-gray-700 mb-0">
                        Let’s try to fix your account issues.
                      </p>
                    </div>
                    <div>
                      <Link
                        to="/create-document"
                        className="btn btn-primary text-white shadow lift"
                      >
                        New Document
                      </Link>
                    </div>
                  </div>
                </div>

                <div
                  className="accordion shadow-light-lg mb-5 mb-md-6"
                  id="helpAccordionOne"
                >
                  <div className="accordion-item">
                    {/* <!-- Button --> */}
                    <div className="accordion-button" role="button">
                      <div className="me-auto">
                        {/* <!-- Heading --> */}
                        <h4 className="fw-bold mb-0">
                          Application and Category Issues
                        </h4>

                        {/* <!-- Text --> */}
                        <p className="fs-sm text-muted mb-0">
                          Application and category related issues
                        </p>
                      </div>

                      {/* <!-- Badge --> */}
                      <span className="badge rounded-pill bg-success-soft ms-4">
                        <span className="h6 text-uppercase">
                          {this.state.loaded && this.state.noOfDocuments}{" "}
                          {this.state.loaded && this.state.noOfDocuments === 1
                            ? "Question"
                            : "Questions"}
                        </span>
                      </span>
                    </div>
                  </div>
                  {this.state.loaded ? (
                    this.renderDocuments()
                  ) : (
                    <ReactBootStrap.Spinner
                      animation="border"
                      variant="primary"
                    />
                  )}
                </div>

                {/* <!-- Divider --> */}
                <hr className="border-gray-300 my-6 my-md-8" />

                {/* <!-- Categories --> */}
                <div className="row">
                  {this.state.loaded ? this.renderApplication() : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    documents: state.document.documents,
    applications: state.applications.applications,
  };
}

export default connect(mapStateToProps, {
  getDocuments,
  deleteDocument,
  getApplications,
})(DocumentationPage);
