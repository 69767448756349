import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Link, generatePath } from "react-router-dom";
import { connect } from "react-redux";
import {
  getDocuments,
  deleteDocument,
} from "../../../modules/Document/_redux/actions";
import { getSingleCategory } from "../../../modules/Category/_redux/actions";
import { getSingleApplication } from "../_redux/actions";
import * as ReactBootStrap from "react-bootstrap";

// The two iterations is to either return the documents that belongs to a category from the backend
// or send the categories for an application alone and loop through it and also loop through the documents and check if the category name is the same
var weekday = new Array(7);
weekday[0] = "Sunday";
weekday[1] = "Monday";
weekday[2] = "Tuesday";
weekday[3] = "Wednesday";
weekday[4] = "Thursday";
weekday[5] = "Friday";
weekday[6] = "Saturday";

function ApplicationDocumentPage(props) {
  const [loaded, setLoaded] = useState(false);
  const [applicationData, setApplicationData] = useState({});
  const [categoriesData, setCategoriesData] = useState([]);
  const [deleted, setDeleted] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(async () => {
    const application = await dispatch(getSingleApplication(id));

    var categoryNameList = [];
    if (application !== undefined) {
      setApplicationData(application.data);
      application.data.Documents.map(async (app) => {
        const category = await dispatch(getSingleCategory(app.categoryId));

        if (category !== undefined && app.categoryId === category.data.id) {
          categoryNameList.push([
            category.data.category_name,
            category.data.id,
            {
              id: app.id,
              subject: app.subject,
              description: app.description,
              updatedAt: app.updatedAt,
              categoryId: app.categoryId,
            },
          ]);
        }

        const categoryList = categoryNameList.reduce(
          (acc, [category, categoryId, data]) => {
            const existingCategory = acc.find(
              (obj) => obj.category === category
            );
            if (!existingCategory) {
              acc.push({ category, categoryId, catDatas: [data] });
            } else {
              acc.forEach((obj) => {
                if (obj.category === category) {
                  obj.catDatas.push(data);
                }
              });
            }
            return acc;
          },
          []
        );

        if (categoryNameList.length > 0) {
          await setCategoriesData(categoryList);
          setLoaded(true);
        }
      });
    }
  }, [deleted]);

  const handleEditPageProceed = (id) => {
    id && props.history.push(generatePath("/edit-document/:id", { id }));
  };

  const confirmDelete = (id) => {
    Swal.fire({
      text: "Are you sure you want to delete?",
      icon: "error",
      showCancelButton: true,
      buttonsStyling: false,
      confirmButtonText: "Yes, delete!",
      cancelButtonText: "No, cancel",
      customClass: {
        confirmButton: "btn font-weight-bold btn-primary",
        cancelButton: "btn font-weight-bold btn-default",
      },
    }).then((result) => {
      if (result.value) {
        dispatch(deleteDocument(id))
          .then((res) => {
            setDeleted(true);
            if (res.status === true) {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                html: "Document deleted successfully",
                confirmButtonColor: "#1BC5BD",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                html: res.message,
                confirmButtonColor: "#1BC5BD",
              });
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const renderDocuments = () => {
    var d;
    var counter = 0;
    if (loaded && categoriesData !== undefined) {
      return categoriesData.map((cat) => {
        {
          d = new Date(document.updatedAt);
        }
        {
          counter += 1;
        }
        return (
          <>
            <div
              className="accordion shadow-light-lg mb-5 mb-md-6"
              id="helpAccordionOne"
            >
              <div className="accordion-item">
                {/* <!-- Button --> */}
                <div className="accordion-button" role="button">
                  <div className="me-auto">
                    {/* <!-- Heading --> */}
                    <h4 className="fw-bold mb-0">{cat.category} Issues</h4>

                    {/* <!-- Text --> */}
                    <p className="fs-sm text-muted mb-0">
                      {cat.category} related issues
                    </p>
                  </div>

                  {/* <!-- Badge --> */}
                  <span className="badge rounded-pill bg-success-soft ms-4">
                    {/* <span className="h6 text-uppercase">{ loaded && applicationData.documents.length } { loaded && applicationData.documents.length === 1 ? "Question" : "Questions" }</span> */}
                  </span>
                </div>
              </div>

              {cat.catDatas.map((data) => {
                return (
                  <div className="accordion-item">
                    {/* <!-- Button --> */}
                    <div
                      className="accordion-button collapsed"
                      role="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#help${counter}`}
                      aria-expanded="false"
                      aria-controls={`help${counter}`}
                    >
                      {/* <!-- Title --> */}
                      <span className="me-4" id={`help${counter}Heading`}>
                        {data.subject}
                      </span>

                      {/* <!-- Metadata --> */}
                      <div className="text-muted ms-auto">
                        {/* <!-- Text --> */}
                        <span className="fs-sm me-4 d-none d-md-inline">
                          Last Updated: Thursday
                        </span>
                      </div>
                    </div>

                    {/* <!-- Collapse --> */}
                    <div
                      className="accordion-collapse collapse"
                      id={`help${counter}`}
                      aria-labelledby={`help${counter}Heading`}
                      data-bs-parent={`#helpAccordion${counter}`}
                    >
                      <div className="accordion-body">
                        {/* <!-- Text --> */}
                        <p className="text-gray-700">{data.description}</p>
                        <div className="d-flex">
                          <div>
                            <i
                              className="fa fa-edit"
                              onClick={() => {
                                handleEditPageProceed(data.id);
                              }}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                          <div>
                            <i
                              className="fa fa-trash"
                              onClick={() => {
                                confirmDelete(data.id);
                              }}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        );
      });
    }
  };

  return (
    <>
      {/* <!-- BREADCRUMB --> */}
      <nav className="bg-gray-200">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <!-- Breadcrumb --> */}
              <ol className="breadcrumb breadcrumb-scroll">
                <li className="breadcrumb-item">
                  <Link to="/" className="text-gray-700">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/application" className="text-gray-700">
                    Applications
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {loaded ? (
                    applicationData.application_name
                  ) : (
                    <ReactBootStrap.Spinner
                      animation="border"
                      variant="primary"
                    />
                  )}
                </li>
                <input type="text" autoFocus className="invisibleInput" />
              </ol>
            </div>
          </div>
        </div>
      </nav>
      <section className="pt-6 pt-md-8 pb-8">
        <div className="container" style={{ maxWidth: "1000px" }}>
          <div className="row">
            <div className="col-12">
              <div className="row mb-6 mb-md-8">
                <div className="col-auto">
                  {/* <!-- Icon --> */}
                  <div className="icon-circle bg-primary text-white">
                    <i className="fe fe-users"></i>
                  </div>
                </div>
                <div className="d-flex justify-content-between col ms-n4">
                  <div>
                    {/* <!-- Heading --> */}
                    <h2 className="fw-bold mb-0">
                      {applicationData.application_name} related issues
                    </h2>

                    {/* <!-- Text --> */}
                    <p className="fs-lg text-gray-700 mb-0">
                      Let’s try to fix your {applicationData.application_name}{" "}
                      issues.
                    </p>
                  </div>
                </div>
              </div>

              {/* <!-- Card --> */}
              {loaded ? (
                renderDocuments()
              ) : (
                <ReactBootStrap.Spinner animation="border" variant="primary" />
              )}
            </div>

            {/* <!-- Divider --> */}
            <hr className="border-gray-300 my-6 my-md-8" />
          </div>
        </div>
      </section>
    </>
  );
}

function mapStateToProps(state) {
  return { documents: state.document.documents };
}

export default connect(mapStateToProps, { getDocuments, deleteDocument })(
  ApplicationDocumentPage
);
