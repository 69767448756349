import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import * as Yup from "yup";
// import Loader from "../../../components/Loader";
import { connect, useDispatch } from "react-redux";
import { getDocument, editDocument } from "../_redux/actions";
import { WizardControl } from "./wizard_control";
import { getApplications } from "../../../modules/Application/_redux/actions";
import { getCategories } from "../../../modules/Category/_redux/actions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function stripHTML(text) {
  var regex = /(<([^>]+)>)/gi;
  return text.replace(regex, "");
}

function EditDocumentForm(props) {
  const [text, setText] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [applicationData, setApplicationData] = useState([]);
  const [documentData, setDocumentData] = useState({});

  const { id } = useParams();
  const dispatch = useDispatch();

  const initialValues = {};

  const configureSchema = Yup.object().shape({});

  useEffect(async () => {
    dispatch(getCategories()).then((res) => {
      setCategoryData(res.data);
      setLoaded(true);
    });

    dispatch(getApplications()).then((res) => {
      setApplicationData(res.data);
      setLoaded(true);
    });

    const document = await dispatch(getDocument(id));

    if (document) {
      setDocumentData(document.data);
      setLoaded(true);
    }

    const subject = "subject";
    const application = "application";
    const category = "category";
    const description = "description";
    const type = "type";
    formik.setFieldValue(subject, document.data.subject, false);
    formik.setFieldValue(type, document.data.type, false);
    formik.setFieldValue(category, document.data.categoryId, false);
    formik.setFieldValue(application, document.data.applicationId, false);
    formik.setFieldValue(description, document.data.description, false);
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: configureSchema,
    onSubmit: (values) => {
      values.id = documentData.id;
      values.status = documentData.status;
      dispatch(editDocument(values))
        .then((res) => {
          if (res.status === true) {
            props.history.push("/documentation");
            Swal.fire({
              icon: "success",
              title: "Updated!",
              html: "Document updated successfully",
              confirmButtonColor: "#1BC5BD",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              html: res.message,
              confirmButtonColor: "#1BC5BD",
            });
          }
        })
        .catch((err) => console.log(err));
    },
  });

  return (
    <>
      {/*begin::Entry*/}
      <div className="d-flex flex-column-fluid">
        {/*begin::Container*/}
        <div className="container wizard-container">
          <div className="card card-custom">
            <div className="card-body p-0">
              {/*begin: Wizard*/}
              <div
                className="wizard wizard-2"
                id="kt_wizard"
                data-wizard-state="step-first"
                data-wizard-clickable="false"
              >
                {/*begin: Wizard Nav*/}
                <div className="wizard-nav border-right py-8 px-8 py-lg-20 px-lg-10">
                  {/*begin::Wizard Step 1 Nav*/}
                  <div className="wizard-steps">
                    <div
                      className="wizard-step"
                      data-wizard-type="step"
                      data-wizard-state="current"
                    >
                      <div className="wizard-wrapper">
                        <div className="wizard-icon">
                          <span className="svg-icon svg-icon-2x">
                            {/*begin::Svg Icon | path:/assets/media/svg/icons/General/User.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              // xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <polygon points="0 0 24 0 24 24 0 24" />
                                <path
                                  d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  opacity="0.3"
                                />
                                <path
                                  d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                        </div>
                        <div className="wizard-label">
                          <h3 className="wizard-title">Select Application</h3>
                          <div className="wizard-desc">
                            Choose application and document type
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Wizard Step 1 Nav*/}
                    {/*begin::Wizard Step 2 Nav*/}
                    <div className="wizard-step" data-wizard-type="step">
                      <div className="wizard-wrapper">
                        <div className="wizard-icon">
                          <span className="svg-icon svg-icon-2x">
                            {/*begin::Svg Icon | path:/assets/media/svg/icons/Map/Compass.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              // xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 Z M14.1654881,7.35483745 L9.61055177,10.3622525 C9.47921741,10.4489666 9.39637436,10.592455 9.38694497,10.7495509 L9.05991526,16.197949 C9.04337012,16.4735952 9.25341309,16.7104632 9.52905936,16.7270083 C9.63705011,16.7334903 9.74423017,16.7047714 9.83451193,16.6451626 L14.3894482,13.6377475 C14.5207826,13.5510334 14.6036256,13.407545 14.613055,13.2504491 L14.9400847,7.80205104 C14.9566299,7.52640477 14.7465869,7.28953682 14.4709406,7.27299168 C14.3629499,7.26650974 14.2557698,7.29522855 14.1654881,7.35483745 Z"
                                  fill="#000000"
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                        </div>
                        <div className="wizard-label">
                          <h3 className="wizard-title">Select Category</h3>
                          <div className="wizard-desc">
                            Add category and subject
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Wizard Step 2 Nav*/}
                    {/*begin::Wizard Step 3 Nav*/}
                    <div className="wizard-step" data-wizard-type="step">
                      <div className="wizard-wrapper">
                        <div className="wizard-icon">
                          <span className="svg-icon svg-icon-2x">
                            {/*begin::Svg Icon | path:/assets/media/svg/icons/General/Thunder-move.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              // xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M16.3740377,19.9389434 L22.2226499,11.1660251 C22.4524142,10.8213786 22.3592838,10.3557266 22.0146373,10.1259623 C21.8914367,10.0438285 21.7466809,10 21.5986122,10 L17,10 L17,4.47708173 C17,4.06286817 16.6642136,3.72708173 16.25,3.72708173 C15.9992351,3.72708173 15.7650616,3.85240758 15.6259623,4.06105658 L9.7773501,12.8339749 C9.54758575,13.1786214 9.64071616,13.6442734 9.98536267,13.8740377 C10.1085633,13.9561715 10.2533191,14 10.4013878,14 L15,14 L15,19.5229183 C15,19.9371318 15.3357864,20.2729183 15.75,20.2729183 C16.0007649,20.2729183 16.2349384,20.1475924 16.3740377,19.9389434 Z"
                                  fill="#000000"
                                />
                                <path
                                  d="M4.5,5 L9.5,5 C10.3284271,5 11,5.67157288 11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L4.5,8 C3.67157288,8 3,7.32842712 3,6.5 C3,5.67157288 3.67157288,5 4.5,5 Z M4.5,17 L9.5,17 C10.3284271,17 11,17.6715729 11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L4.5,20 C3.67157288,20 3,19.3284271 3,18.5 C3,17.6715729 3.67157288,17 4.5,17 Z M2.5,11 L6.5,11 C7.32842712,11 8,11.6715729 8,12.5 C8,13.3284271 7.32842712,14 6.5,14 L2.5,14 C1.67157288,14 1,13.3284271 1,12.5 C1,11.6715729 1.67157288,11 2.5,11 Z"
                                  fill="#000000"
                                  opacity="0.3"
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                        </div>
                        <div className="wizard-label">
                          <h3 className="wizard-title">Document description</h3>
                          <div className="wizard-desc">
                            Add description and additional files
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*end::Wizard Step 3 Nav*/}

                    {/*begin::Wizard Step 6 Nav*/}
                    <div className="wizard-step" data-wizard-type="step">
                      <div className="wizard-wrapper">
                        <div className="wizard-icon">
                          <span className="svg-icon svg-icon-2x">
                            {/*begin::Svg Icon | path:/assets/media/svg/icons/General/Like.svg*/}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              // xmlns:xlink="http://www.w3.org/1999/xlink"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <path
                                  d="M9,10 L9,19 L10.1525987,19.3841996 C11.3761964,19.7920655 12.6575468,20 13.9473319,20 L17.5405883,20 C18.9706314,20 20.2018758,18.990621 20.4823303,17.5883484 L21.231529,13.8423552 C21.5564648,12.217676 20.5028146,10.6372006 18.8781353,10.3122648 C18.6189212,10.260422 18.353992,10.2430672 18.0902299,10.2606513 L14.5,10.5 L14.8641964,6.49383981 C14.9326895,5.74041495 14.3774427,5.07411874 13.6240179,5.00562558 C13.5827848,5.00187712 13.5414031,5 13.5,5 L13.5,5 C12.5694044,5 11.7070439,5.48826024 11.2282564,6.28623939 L9,10 Z"
                                  fill="#000000"
                                />
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  x="2"
                                  y="9"
                                  width="5"
                                  height="11"
                                  rx="1"
                                />
                              </g>
                            </svg>
                            {/*end::Svg Icon*/}
                          </span>
                        </div>
                        <div className="wizard-label">
                          <h3 className="wizard-title">Completed!</h3>
                          <div className="wizard-desc">Review and Submit</div>
                        </div>
                      </div>
                    </div>
                    {/*end::Wizard Step 6 Nav*/}
                  </div>
                </div>
                {/*end: Wizard Nav*/}
                {/*begin: Wizard Body*/}
                <div className="wizard-body py-8 px-8 py-lg-20 px-lg-10">
                  {/*begin: Wizard Form*/}
                  <div className="row">
                    <div className="offset-xxl-2 col-xxl-8">
                      <form
                        className="form"
                        id="kt_form"
                        onSubmit={formik.handleSubmit}
                      >
                        {/*begin: Wizard Step 1*/}
                        <div
                          className="pb-5"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                          <h4 className="mb-10 font-weight-bold text-dark">
                            Enter your Application Details
                          </h4>
                          {/*begin::Input*/}
                          <div className="form-group">
                            <label>Application</label>
                            <select
                              name="application"
                              className="form-control form-control-solid form-control-lg"
                              value={formik.values.application}
                              onChange={formik.handleChange}
                            >
                              <option value="">-- Select One --</option>
                              {loaded
                                ? applicationData.map((application) => {
                                    return (
                                      <option value={application.id}>
                                        {application.application_name}
                                      </option>
                                    );
                                  })
                                : null}
                              {/* <!--begin::Select--> */}
                            </select>
                            <span className="wizard-desc text-muted">
                              Please select application.
                            </span>
                            {/* {errors.application && touched.application ? (
                                            <div>{errors.application}</div>
                                          ) : null} */}
                          </div>

                          <div
                            className="form-group row d-flex"
                            style={{ marginTop: "20px" }}
                          >
                            <div>
                              <label className="col-form-label">
                                Document Type
                              </label>
                            </div>
                            <div className="col-9 col-form-label">
                              {/* <RadioGroup
                                         name="type"
                                         checked={formik.values.type}
                                         value={documentData.type !== undefined ? documentData.type.toString() : null}
                                         onChange={formik.handleChange}
                                      
                                        >
                                        <FormControlLabel control={<Radio />} value={"INTERNAL"}  label="Internal" />
                                        <FormControlLabel control={<Radio />} value={"EXTERNAL"}  label="External" />
                                     
                                      </RadioGroup> */}
                              <div className="radio-inline">
                                <label className="radio internal">
                                  <input
                                    type="radio"
                                    name="type"
                                    onChange={formik.handleChange}
                                    value="INTERNAL"
                                    className="radio-btn"
                                  />
                                  <span></span>Internal
                                </label>
                                <label className="radio external">
                                  <input
                                    type="radio"
                                    name="type"
                                    onChange={formik.handleChange}
                                    value="EXTERNAL"
                                    className="radio-btn"
                                  />
                                  <span></span>External
                                </label>
                              </div>
                              <span className="wizard-desc text-muted">
                                Internal documents are only visible to
                                department members
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*end::Input*/}

                        {/*begin: Wizard Step 2*/}
                        <div className="pb-5" data-wizard-type="step-content">
                          <h4 className="mb-10 font-weight-bold text-dark">
                            Select Category and Subject
                          </h4>
                          <div className="row">
                            <div className="col-xl-12">
                              {/*begin::Input*/}
                              <div className="form-group">
                                <label>Category</label>
                                <select
                                  name="category"
                                  className="form-control form-control-solid form-control-lg"
                                  onChange={formik.handleChange}
                                  value={formik.values.category}
                                >
                                  <option value="">-- Select One --</option>
                                  {loaded
                                    ? categoryData.map((category) => {
                                        return (
                                          <option value={category.id}>
                                            {category.category_name}
                                          </option>
                                        );
                                      })
                                    : null}
                                  {/* <!--begin::Select--> */}
                                </select>
                                <span className="wizard-desc text-muted">
                                  Please select category.
                                </span>
                                {/* {errors.category && touched.category ? (
                                            <div>{errors.category}</div>
                                          ) : null} */}
                              </div>
                              {/*end::Input*/}
                            </div>
                            <div className="col-xl-12">
                              {/*begin::Input*/}
                              <div className="form-group">
                                <label>Subject</label>
                                <input
                                  name="subject"
                                  className="form-control form-control-solid form-control-lg"
                                  placeholder="Subject"
                                  onChange={formik.handleChange}
                                  value={formik.values.subject}
                                />

                                <span className="wizard-desc text-muted">
                                  Please enter subject.
                                </span>
                                {/* {errors.subject && touched.subject ? (
                                            <div>{errors.subject}</div>
                                          ) : null} */}
                              </div>
                              {/*end::Input*/}
                            </div>
                          </div>
                        </div>
                        {/*end: Wizard Step 2*/}
                        {/*begin: Wizard Step 3*/}
                        <div className="pb-5" data-wizard-type="step-content">
                          <h4 className="mb-10 font-weight-bold text-dark">
                            Enter document description
                          </h4>
                          <div
                            className="form-group row"
                            style={{ width: "400px" }}
                          >
                            <CKEditor
                              editor={ClassicEditor}
                              data={documentData.description}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                formik.setFieldValue(
                                  "description",
                                  stripHTML(data)
                                );
                              }}
                            />
                            {/* <ReactQuill theme="snow" modules={EditDocumentForm.modules} formats={ EditDocumentForm.formats } onChange={(v) => formik.setFieldValue("description", stripHTML(v)  )} /> */}
                          </div>
                          <div className="form-group row">
                            {/* <label>Upload Additional Files:</label> */}
                            <div className="col-lg-9">
                              <div
                                className="dropzone dropzone-multi"
                                id="kt_dropzone_4"
                              >
                                <div className="dropzone-panel mb-lg-0 mb-2">
                                  {/* <button className="dropzone-select btn btn-light-primary font-weight-bold btn-sm">Attach files</button> */}
                                  {/* <a className="dropzone-upload btn btn-light-primary font-weight-bold btn-sm">Upload All</a>
                                                          <a className="dropzone-remove-all btn btn-light-primary font-weight-bold btn-sm">Remove All</a> */}
                                </div>
                                <div className="dropzone-items">
                                  <div
                                    className="dropzone-item"
                                    style={{ display: "none" }}
                                  >
                                    <div className="dropzone-file">
                                      <div
                                        className="dropzone-filename"
                                        title="some_image_file_name.jpg"
                                      >
                                        <span data-dz-name="">
                                          some_image_file_name.jpg
                                        </span>
                                        <strong>
                                          (<span data-dz-size="">340kb</span>)
                                        </strong>
                                      </div>
                                      <div
                                        className="dropzone-error"
                                        data-dz-errormessage=""
                                      ></div>
                                    </div>
                                    <div className="dropzone-progress">
                                      <div className="progress">
                                        <div
                                          className="progress-bar bg-primary"
                                          role="progressbar"
                                          aria-valuemin="0"
                                          aria-valuemax="100"
                                          aria-valuenow="0"
                                          data-dz-uploadprogress=""
                                        ></div>
                                      </div>
                                    </div>
                                    <div className="dropzone-toolbar">
                                      <span className="dropzone-start">
                                        <i className="flaticon2-arrow"></i>
                                      </span>
                                      <span
                                        className="dropzone-cancel"
                                        data-dz-remove=""
                                        style={{ display: "none" }}
                                      >
                                        <i className="flaticon2-cross"></i>
                                      </span>
                                      <span
                                        className="dropzone-delete"
                                        data-dz-remove=""
                                      >
                                        <i className="flaticon2-cross"></i>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <span className="wizard-desc text-muted">Max file size is 1MB and max number of files is 5.</span> */}
                            </div>
                          </div>
                        </div>
                        {/*end: Wizard Step 3*/}

                        {/*begin: Wizard Step 6*/}
                        <div className="pb-5" data-wizard-type="step-content">
                          {/*begin::Section*/}
                          {/*begin::Section*/}
                          <h5 className="mb-5 font-weight-bold text-dark">
                            Review your Details and Submit
                          </h5>

                          <h5 className="mb-3" style={{ fontWeight: "900" }}>
                            Subject:{" "}
                            <span style={{ fontWeight: "400" }}>
                              {formik.values.subject}
                            </span>
                          </h5>
                          <div className="separator separator-dashed my-5"></div>
                          <h5 className="mb-3" style={{ fontWeight: "900" }}>
                            Type:{" "}
                            <span style={{ fontWeight: "400" }}>
                              {formik.values.type}
                            </span>
                          </h5>
                          <div className="separator separator-dashed my-5"></div>

                          {/*begin::Section*/}
                          <h5 className="mb-3" style={{ fontWeight: "900" }}>
                            Document Description:{" "}
                            <span style={{ fontWeight: "400" }}>
                              {formik.values.description}
                            </span>
                          </h5>

                          <div className="separator separator-dashed my-5"></div>
                          {/*end::Section*/}
                          {/*end::Section*/}
                        </div>
                        {/*end: Wizard Step 6*/}
                        {/*begin: Wizard Actions*/}
                        <div className="d-flex justify-content-between border-top mt-5 pt-10 wizard-demac">
                          <div className="mr-2">
                            <button
                              type="button"
                              className="btn btn-primary-soft font-weight-bolder text-uppercase previous"
                              data-wizard-type="action-prev"
                            >
                              Previous
                            </button>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 submit"
                              data-wizard-type="action-submit"
                              disabled={formik.isSubmitting}
                              onClick={() => {
                                setLoaded(true);
                                setLoading(true);
                                formik.handleSubmit();
                              }}
                            >
                              Submit{" "}
                              {loading ? (
                                <span className="spinner-border text-light"></span>
                              ) : null}
                              {/* {loading && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary font-weight-bolder text-uppercase px-9 py-4 next"
                              data-wizard-type="action-next"
                            >
                              Next
                            </button>
                          </div>
                        </div>
                        {/*end: Wizard Actions*/}
                      </form>
                      {/* <WizardControl /> */}
                    </div>
                    {/*end: Wizard*/}
                  </div>
                  {/*end: Wizard Form*/}
                </div>
                {/*end: Wizard Body*/}
              </div>
              {/*end: Wizard*/}
            </div>
          </div>
        </div>
        {/*end::Container*/}
      </div>

      {/* ABOUT */}
      <section class="py-8 py-md-11 bg-gray-200">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7 text-center">
              {/* Heading */}
              <h2 class="fw-bold">Built for teams of all kinds.</h2>

              {/* Text */}
              <p class="fs-lg text-muted mb-7 mb-md-9">
                No matter what you're working on, who you're with, or how many
                of you there are, Landkit can help.
              </p>
            </div>
          </div>{" "}
          {/* / .row */}
          <div class="row gx-4">
            <div class="col-12 col-lg-6 d-lg-flex mb-4">
              {/* Card */}
              <div
                class="card shadow-light-lg overflow-hidden"
                data-aos="fade-up"
              >
                <div class="row">
                  <div class="col-md-4 position-relative">
                    {/* Image */}
                    <img
                      src="/assets/img/illustrations/illustration-8.png"
                      class="h-75 position-absolute right-0 mt-7 me-n4"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    {/* Body */}
                    <div class="card-body py-7 py-md-9 text-center">
                      {/* Heading */}
                      <h4 class="fw-bold">Unlimited Team Size</h4>

                      {/* Text */}
                      <p class="text-muted mb-0">
                        Landkit works well for a scrappy team of 3 or scales to
                        the enterprise level needs of Forture 500 companies.
                      </p>
                    </div>
                  </div>
                </div>{" "}
                {/* row */}
              </div>
            </div>
            <div class="col-12 col-lg-6 d-lg-flex mb-4">
              {/* Card */}
              <div
                class="card shadow-light-lg overflow-hidden text-center"
                data-aos="fade-up"
              >
                <div class="row">
                  <div class="col-md-8">
                    {/* Body */}
                    <div class="card-body py-7 py-md-9">
                      {/* Heading */}
                      <h4 class="fw-bold">Emoji Responses</h4>

                      {/* Text */}
                      <p class="text-muted mb-0">
                        Instead of bombarding team members with huge messages,
                        simply reply with an emoji to express your reply.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-4">
                    {/* Image */}
                    <img
                      src="/assets/img/illustrations/illustration-6.png"
                      class="h-75 position-absolute left-0 mt-7"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              {/* Card */}
              <div
                class="card shadow-light-lg overflow-hidden text-center text-lg-start"
                data-aos="fade-up"
              >
                <div class="row">
                  <div class="col-md-4 position-relative">
                    {/* Image */}
                    <img
                      src="/assets/img/illustrations/illustration-2.png"
                      class="h-75 position-absolute right-0 mt-6"
                      alt="..."
                    />
                  </div>
                  <div class="col-md-8">
                    {/* Body */}
                    <div class="card-body py-7 py-md-9">
                      {/* Heading */}
                      <h4 class="fw-bold">Building a community.</h4>

                      {/* Text */}
                      <p class="text-muted mb-0">
                        Landkit is focused on helping foster a community. Lorem
                        ipsum dolor sit amet, consectetur adipiscing elit. Proin
                        quis diam tempus tortor egestas blandit. Aliquam erat
                        volutpat. Phasellus rhoncus pretium molestie. Nulla
                        facilisi. Etiam sollicitudin lectus ac nisi tincidunt
                        porttitor. Phasellus eros metus, gravida eu mi ac,
                        gravida convallis ipsum.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- PROCESS --> */}
      <section class="pt-8 pt-md-11">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-12 col-md-6">
              {/* <!-- Preheading --> */}
              <h6 class="text-uppercase text-primary fw-bold">Process</h6>

              {/* <!-- Heading --> */}
              <h2>
                Our process to find you a new job is fast and you can do it from
                home.
              </h2>

              {/* <!-- Text --> */}
              <p class="lead text-muted mb-6 mb-md-0">
                We keep everything as simple as possible by standardizing the
                application process for all jobs.
              </p>
            </div>
            <div class="col-12 col-md-6 col-xl-5">
              <div class="row gx-0">
                <div class="col-4">
                  {/* <!-- Image --> */}
                  <div
                    class="w-150 mt-9 p-1 bg-white shadow-lg"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <img
                      src="/assets/img/photos/photo-13.jpg"
                      class="img-fluid"
                      alt="..."
                    />
                  </div>
                </div>
                <div class="col-4">
                  {/* <!-- Image --> */}
                  <div class="w-150 p-1 bg-white shadow-lg" data-aos="fade-up">
                    <img
                      src="/assets/img/photos/photo-14.jpg"
                      class="img-fluid"
                      alt="..."
                    />
                  </div>
                </div>
                <div class="col-4 position-relative">
                  {/* <!-- Image --> */}
                  <div
                    class="w-150 mt-11 float-end p-1 bg-white shadow-lg"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <img
                      src="assets/img/photos/photo-15.jpg"
                      class="img-fluid"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- STEPS --> */}
      <section class="pt-8 pb-9 pt-md-11 pb-md-13">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-auto col-md-12">
                  {/* <!-- Step --> */}
                  <div class="row gx-0 align-items-center mb-md-5">
                    <div class="col-auto">
                      <a
                        href="#!"
                        class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                      >
                        <span>1</span>
                      </a>
                    </div>
                    <div class="col">
                      <hr class="d-none d-md-block me-n7" />
                    </div>
                  </div>
                </div>
                <div class="col col-md-12 ms-n5 ms-md-0">
                  {/* <!-- Heading --> */}
                  <h3>Complete your application.</h3>

                  {/* <!-- Text --> */}
                  <p class="text-muted mb-6 mb-md-0">
                    Fill out our standardized application on our platform. Most
                    applicants finish in under an hour.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-auto col-md-12">
                  {/* <!-- Step --> */}
                  <div class="row gx-0 align-items-center mb-md-5">
                    <div class="col-auto">
                      <a
                        href="#!"
                        class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                      >
                        <span>2</span>
                      </a>
                    </div>
                    <div class="col">
                      <hr class="d-none d-md-block me-n7" />
                    </div>
                  </div>
                </div>
                <div class="col col-md-12 ms-n5 ms-md-0">
                  {/* <!-- Heading --> */}
                  <h3>Select companies.</h3>

                  {/* <!-- Text --> */}
                  <p class="text-muted mb-6 mb-md-0">
                    We'll immediately match you with any relevant openings and
                    you get to pick which ones you're interested in.
                  </p>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-auto col-md-12">
                  {/* <!-- Step --> */}
                  <div class="row gx-0 align-items-center mb-md-5">
                    <div class="col-auto">
                      <a
                        href="#!"
                        class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1"
                      >
                        <span>3</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col col-md-12 ms-n5 ms-md-0">
                  {/* <!-- Heading --> */}
                  <h3>Choose your offer.</h3>

                  {/* <!-- Text --> */}
                  <p class="text-muted mb-0">
                    After 3 days all of your offers will arrive and you will
                    have another 7 days to select your new company.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CTA */}
      <section class="py-md-12 bg-gray-200 bg-between">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">
              {/* Heading */}
              <h2 class="fw-bold">Try Landkit for free anytime, anywhere.</h2>

              {/* Text */}
              <p class="fs-lg text-muted mb-8 px-lg-9">
                We can help you build a better business faster. Tried and true
                systems for projects small and large.
              </p>

              {/* Button */}
              <a href="#!" class="btn btn-primary-desat lift">
                Get started for free
              </a>
            </div>
          </div>
        </div>
      </section>

      <WizardControl />
      {/*end::Entry*/}
    </>
  );
}

function mapStateToProps(state) {
  return {
    categories: state.categories.categories,
    applications: state.applications.applications,
  };
}

EditDocumentForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { header: [3, 4, 5, 6] }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["clean"],
    ["code-block"],
  ],
};

EditDocumentForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];

export default connect(mapStateToProps, {
  getCategories,
  getApplications,
  getDocument,
  editDocument,
})(EditDocumentForm);
