import React, { Suspense, lazy } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen } from "./components/SplashScreen";
import {Navbar} from './components/Navbar'
import {Footer} from './components/Footer'
import {LandingPage} from './pages/LandingPage'
import Login from './modules/Auth/pages/Login'
import Logout from './modules/Auth/pages/Logout'
import DocumentationPage from "./modules/Document/pages/Documentation"
import ApplicationDocumentPage from "./modules/Application/pages/ApplicationDocumentPage"
import CategoryDocumentPage from "./modules/Category/pages/CategoryDocumentPage"
import CreateDocumentPage from "./modules/Document/component/CreateDocument"
import EditDocumentForm from "./modules/Document/component/EditDocument1"


const ApplicationPage = lazy(() =>
  import("./modules/Application/pages/ApplicationPage1")
);

const CategoryPage = lazy(() =>
  import("./modules/Category/pages/CategoryPage1")
);



export function Routes() {

  const {isAuthorized} = useSelector(
    ({auth}) => ({
        isAuthorized: auth.user != null,
    }),
    shallowEqual
);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <Navbar />
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route path="/signin" component={Login} />
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from="/signin" to="/"/>
            )}
            {/* <Route path="/error" component={ErrorsPage} /> */}
            <Route path='/logout' component={Logout} />
            <Route path="/" exact component={LandingPage} />
            <Route path="/signin" component={Login} />
            <Route path="/edit-document/:id" component={EditDocumentForm} />
            <Route path="/documentation" component={DocumentationPage} />
            <Route path="/create-document" component={CreateDocumentPage} />
            <Route path="/application" exact component={ApplicationPage} />
            <Route path="/application/:id/documents" component={ApplicationDocumentPage} />
            <Route path="/category/:id/documents" component={CategoryDocumentPage} />
            <Route path="/category" component={CategoryPage} />
      </Switch>
      <Footer />
    </Suspense>
  );
}