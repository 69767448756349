import {
  GET_DOCUMENT,
  GET_SINGLE_DOCUMENT,
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
  UPDATE_DOCUMENT
} from "../actions/types";

const initialState = {
    documents : []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENT:
      return {
        ...state,
      documents: action.payload
      };
    case GET_SINGLE_DOCUMENT:
      return {
        ...state,
      document: action.payload
      };
    case CREATE_DOCUMENT:
        return {
          ...state,
          document: action.payload.data,
        };
    case UPDATE_DOCUMENT:
      // const objIndex = state.documents.data.findIndex((obj => obj.id === action.payload.data.id))
      // state.documents.data[objIndex].subject = action.payload.data.subject
      // state.documents.data[objIndex].category = action.payload.data.category
      // state.documents.data[objIndex].application = action.payload.data.application
      // state.documents.data[objIndex].description = action.payload.data.description
      return {
        ...state,
        documents: { data: state.documents.data } || null
        };
    case DELETE_DOCUMENT:
      const info = [...state.documents.data.filter((doc) => doc.id !== action.payload)]
        const info1 = {
          data: info || null
        }
      return {
        ...state,
        documents: info1 || null,
      };
    default:
      return state;
  }
}
