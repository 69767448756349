import axios from "axios";
import {
  GET_CATEGORIES,
  GET_SINGLE_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "./types";

const CATEGORY_URL = process.env.REACT_APP_API_ENDPOINT + "/categories";

export const createCategory = (values) => async (dispatch) => {
  const res = await axios.post(CATEGORY_URL, {
    category: {
      category_name: values.category,
      category_description: values.description,
    },
  });

  dispatch({ type: CREATE_CATEGORY, payload: res.data });
  return res.data;
};

export const getSingleCategory = (id) => async (dispatch) => {
  const res = await axios.get(`${CATEGORY_URL}/${id}`);

  dispatch({ type: GET_SINGLE_CATEGORY, payload: res.data });
  return res.data;
};

export const updateCategory = (values, id) => async (dispatch) => {
  const res = await axios.put(`${CATEGORY_URL}/${id}`, {
    category: {
      category_name: values.category,
      category_description: values.description,
    },
  });

  dispatch({ type: UPDATE_CATEGORY, payload: res.data });
  return res.data;
};

export const getCategories = () => async (dispatch) => {
  const res = await axios.get(CATEGORY_URL);

  dispatch({ type: GET_CATEGORIES, payload: res.data });
  return res.data;
};

export const deleteCategory = (values) => async (dispatch) => {
  const res = await axios.delete(`${CATEGORY_URL}/${values}`);

  dispatch({ type: DELETE_CATEGORY, payload: values });
  return res.data;
};
