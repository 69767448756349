import React from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

export function Navbar() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container-fluid">
        {/* <!-- Brand --> */}
        <a className="navbar-brand" href="https://singlelogin.io">
          {/* <img src="assets/img/brand.svg" className="navbar-brand-img" alt="..." /> */}
          DocuTrack
        </a>

        {/* <!-- Toggler --> */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        {/* <!-- Collapse --> */}
        <div className="collapse navbar-collapse" id="navbarCollapse">
          {/* <!-- Toggler --> */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fe fe-x"></i>
          </button>

          {/* <!-- Navigation --> */}
          <ul className="navbar-nav ms-auto">
            <li className="nav-item dropdown">
              <a href="https://singlelogin.io" className="nav-link">
                Home
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                id="navbarAccount"
                data-bs-toggle="dropdown"
                href="/"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Pages
              </a>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarAccount"
                style={{ minWidth: "90px", padding: "16px 28px" }}
              >
                <li className="dropdown-item">
                  <Link to="/category" className="dropdown-link">
                    Category
                  </Link>
                </li>
                <li className="dropdown-item">
                  <Link to="/application" className="dropdown-link">
                    Application
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <Link
                className="nav-link"
                to="/documentation"
                aria-haspopup="true"
              >
                Documentation
              </Link>
            </li>
          </ul>

          {!isAuthorized ? (
            <Link
              className="navbar-btn btn btn-sm btn-primary lift ms-auto"
              to="/signin"
            >
              Sign In
            </Link>
          ) : (
            <Link
              className="navbar-btn btn btn-sm btn-primary lift ms-auto"
              to="/logout"
            >
              Logout
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
}
