import Swal from 'sweetalert2'

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState()

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`
      }

      return config
    },
    (err) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response !== undefined){
        if (error.response.status === 400 || error.response.status === 500) {
          Swal.fire({
            icon: 'error',
            title: 'Not connected',
            html: `<strong>Internal server error</strong></strong>`,
          })
          // return {
          //   ...error.response
          // }
        }
        else if (error.response.status === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Session expired',
            html: `<strong>Your session has expired. Login again</strong>`,
          }).then(() => {
            window.location.href = '/logout'
          })
        }
      }
      
      return error
    }
  )
}
