import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../modules/Auth/_redux/authRedux"
import applicationReducer from "../modules/Application/_redux/reducers/applicationReducer";
import categoryReducer from "../modules/Category/_redux/reducers/categoryReducer";
import documentReducer from "../modules/Document/_redux/reducers/documentReducer";

// import { quizesSlice } from '../app/modules/Application/redux/Quiz/quizSlice'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  applications: applicationReducer,
  categories: categoryReducer,
  document: documentReducer,

});

export function* rootSaga() {
  yield all([auth.saga()]);
}
