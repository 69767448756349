import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import {SplashScreenProvider} from './components/SplashScreen'
import axios from "axios";
import * as _redux from "./redux";
import store, { persistor } from "./redux/store";

const { PUBLIC_URL } = process.env;

_redux.setupAxios(axios, store);
ReactDOM.render(
  <SplashScreenProvider>
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </SplashScreenProvider>,
  document.getElementById('root')
);
