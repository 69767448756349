import {
    GET_CATEGORIES,
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY,
    GET_SINGLE_CATEGORY
  } from "../actions/types";
  
  const initialState = {
    category: []
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_CATEGORIES:
        return {
            ...state,
            categories: action.payload
        };
      case GET_SINGLE_CATEGORY:
        return {
            ...state,
            category: action.payload
        };
      case CREATE_CATEGORY:
        return {
          ...state,
          category: action.payload !== undefined ? action.payload.data : null,
        };
      case UPDATE_CATEGORY:
          console.log(action.payload)
          const objIndex = state.categories.data.findIndex((obj => obj.id === action.payload.data.id))
          state.categories.data[objIndex].category_name = action.payload.data.category_name
          state.categories.data[objIndex].description = action.payload.data.description
          return {
            ...state,
            categories: { data: state.categories.data.filter((el) => { return el !== null }) } || null
            };
      case DELETE_CATEGORY:
        const info = [...state.categories.data.filter((cat) => cat.id !== action.payload)]
          console.log("payload", info)
          const info1 = {
            data: info
          }
        return {
          ...state,
          categories: info1
        };
  
      default:
        return state;
    }
  }
  